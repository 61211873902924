// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$personality-exam-primary: mat-palette($mat-indigo);
$personality-exam-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$personality-exam-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$personality-exam-theme: mat-light-theme((
  color: (
    primary: $personality-exam-primary,
    accent: $personality-exam-accent,
    warn: $personality-exam-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($personality-exam-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'OurFontRegular';
  src: url('./assets/fonts/almoni-neue-aaa-400.eot');
  src: url('./assets/fonts/almoni-neue-aaa-400.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/almoni-neue-aaa-400.woff') format('woff');
}

@font-face {
  font-family: 'OurFontLight';
  src: url('./assets/fonts/almoni-neue-aaa-300.eot');
  src: url('./assets/fonts/almoni-neue-aaa-300.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/almoni-neue-aaa-300.woff') format('woff');
}

@font-face {
  font-family: 'OurFontBold';
  src: url('./assets/fonts/almoni-neue-aaa-700.eot');
  src: url('./assets/fonts/almoni-neue-aaa-700.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/almoni-neue-aaa-700.woff') format('woff');
}

@font-face {
  font-family: 'OurFontBolder';
  src: url('./assets/fonts/almoni-neue-aaa-900.eot');
  src: url('./assets/fonts/almoni-neue-aaa-900.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/almoni-neue-aaa-900.woff') format('woff');
}

html, body {
  height: 100vh;
  direction: rtl;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: OurFontRegular, sans-serif;
  text-align: right;
  overflow-x: hidden;
  background: transparent linear-gradient(241deg, #FDFDFE 0%, #DEE8F4 100%) 0% 0% no-repeat padding-box;
  //min-height: 1500px;
}

.mat-horizontal-content-container,
.mat-horizontal-stepper-content {
}

.mainContainer .mat-horizontal-stepper-header-container {
  display: none;
}

.mainContainer .mat-horizontal-content-container {
  padding: 0;
}

.mainContainer mat-horizontal-stepper {
  background: transparent;
}

body .mat-snack-bar-container {
  text-align: right;
  direction: rtl;
}

.mat-snack-bar-container .mat-simple-snackbar-action {
  flex-shrink: 0;
  margin: -8px 8px -8px -8px;
}

p {
  color: #2B3A63;
  font-size: 1.4rem;
  line-height: 1.5;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: OurFontRegular, sans-serif;
}

.actionPage .logo {
  margin-bottom: 2rem !important;
}


@media(min-width: 768px) {
  .actionPage .logo {
    margin-bottom: 3rem !important;
  }
}


@media only screen and (max-height: 700px) {
}
